<template>
  <div>
    <PageLoader />
    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span class="homeTop"> About Us Content </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12 md12>
                <v-text-field
                  class="homeText"
                  dense
                  outlined
                  v-model="about.top.title"
                  label="Title of the About Us"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="about.top.topCaption"
                  label="Caption of the About Us"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pb-5>
                <span class="homeText">Description of the About Us</span>
                <div id="app">
                  <vue-editor v-model="about.top.description"></vue-editor>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="about.middle.leftText"
                  label="Left Text of the About Us"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 pb-5>
                <span class="homeText">Right Text of the About Us</span>
                <div id="app">
                  <vue-editor v-model="about.middle.rightText"></vue-editor>
                </div>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="about.bottom.missionTitle"
                  label="Mission Title of the About Us"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  class="homeText"
                  outlined
                  dense
                  v-model="about.bottom.missionText"
                  label="Mission Text of the About Us"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="about.bottom.missionColor"
                ></v-color-picker>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  class="homeText"
                  outlined
                  dense
                  v-model="about.bottom.visionTitle"
                  label="Vision Title of the About Us"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  class="homeText"
                  outlined
                  dense
                  v-model="about.bottom.visionText"
                  label="Vision Text of the About Us"
                ></v-textarea>
              </v-flex>
              <v-flex xs12>
                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  v-model="about.bottom.visionColor"
                ></v-color-picker>
              </v-flex>
              <v-layout justify-start wrap pl-2 pb-5>
                <v-flex xs12>
                  <img
                    v-if="imagePreview"
                    :src="imagePreview"
                    v-show="showPreview"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                  <img
                    v-else
                    :src="mediaURL + about.top.image"
                    width="150px"
                    height="150px"
                    class="align-center"
                    contain
                  />
                </v-flex>
                <v-flex xs12 sm7 md5 lg4>
                  <v-btn
                    width="180px"
                    block
                    tile
                    depressed
                    :ripple="false"
                    class="text-capitalize"
                    color="#F6F5FF"
                    @click="$refs.showImage.click()"
                  >
                    <v-layout justify-center wrap>
                      <v-flex xs2 sm2 md2 text-left>
                        <v-icon style="color: #000000">mdi-upload</v-icon>
                      </v-flex>
                      <v-flex xs10 sm8 md8>
                        <span class="image">Upload About Us Top Image</span>
                      </v-flex>
                    </v-layout>
                  </v-btn>
                  <input
                    v-show="false"
                    accept="image/*"
                    id="file"
                    ref="showImage"
                    multiple="multiple"
                    type="file"
                    @change="showImage"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="black"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
// import ImageComp from "@/components/Common/singleImage";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    // ImageComp,
    VueEditor,
  },
  data() {
    return {
      about: {
        top: {
          title: null,
          topCaption: null,
          description: null,
        },
        middle: { leftText: null, rightText: null },
        bottom: {
          missionTitle: null,
          missionText: null,
          missionColor: null,
          visionTitle: null,
          visionText: null,
          visionColor: null,
        },
      },
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      imageArray: [],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      imagePreview: "",
      showPreview: false,
      file: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      selectedImage: null,
      topimageFile: null,
      producttopimagefile: null,
      productrightimagefile: null,
      imageFiles: [],
      id: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    validateInput() {
      if (!this.about.top.title) {
        this.msg = "Please Provide title";
        this.showSnackBar = true;
        return;
      } else if (!this.about.top.topCaption) {
        this.msg = "Please Provide Top caption";
        this.showSnackBar = true;
        return;
      } else if (!this.about.top.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      } else if (!this.about.middle.leftText) {
        this.msg = "Please Provide Left Text";
        this.showSnackBar = true;
        return;
      } else if (!this.about.middle.rightText) {
        this.msg = "Please Provide Right Text";
        this.showSnackBar = true;
        return;
      } else if (!this.about.bottom.missionTitle) {
        this.msg = "Please Provide Mission Title";
        this.showSnackBar = true;
        return;
      } else if (!this.about.bottom.missionText) {
        this.msg = "Please Provide Mission Text";
        this.showSnackBar = true;
        return;
      } else if (!this.about.bottom.visionTitle) {
        this.msg = "Please Provide Vision Title";
        this.showSnackBar = true;
        return;
      } else if (!this.about.bottom.visionText) {
        this.msg = "Please Provide Vision Title";
        this.showSnackBar = true;
        return;
      } else {
        this.add();
      }
    },
    add() {
      this.about.bottom["missionColor"] = this.about.bottom.missionColor.hexa;
      this.about.bottom["visionColor"] = this.about.bottom.visionColor.hexa;
      axios({
        method: "post",
        url: "/aboutus/set",
        data: this.about,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // this.id = response.data.data._id;
            if (this.imagePreview) {
              this.uploadImage();
            }
            this.msg = " Added Successully";
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      store.commit("appLoading", true);
      axios({
        method: "GET",
        url: "/aboutus/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.about = response.data.data;
          store.commit("appLoading", false);
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.$refs.showImage.value = null;
        this.imagePreview = e.target.result;
      };
      if (this.file.size > 2145728) {
        this.msg = "File Should be less than 2MB";
        this.showSnackBar = true;
        return;
      }
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      this.appLoading = true;
      //   this.formDataCover.append("id", item);
      this.formDataCover.append("image", this.file);
      axios({
        method: "POST",
        url: "/aboutus/image",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.formDataCover = new FormData();
            this.msg = "Upload Successfully";
            this.showSnackBar = true;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.v-application .primary {
  background-color: red !important;
}
</style>